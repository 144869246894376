import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {

  @ViewChild('root') root;

  unsubscribe = false;
  advPro = false;

  // Array of login images with their corresponding URLs
  loginImages = [
   
    {
      image: 'assets/img/login/b_t_dataset.svg',
      url: 'https://advizorpro.com/bank-and-trust-data/'
    },
    
    {
      image: 'assets/img/login/401(k)_plans_dataset.png',
      url: 'https://advizorpro.com/planpro/'
    },
    {
      image: 'assets/img/login/ai_search.svg',
      url: 'https://advizorpro.com/ai-search/'
    },
    {
      image: 'assets/img/login/family_office_dataset.svg',
      url: 'https://advizorpro.com/family-office-data/'
    },
    {
      image: 'assets/img/login/insurance_dataset.svg',
      url: 'https://advizorpro.com/insurance-producer-database/'
    },
    {
      image: 'assets/img/login/lead_scoring.svg',
      url: 'https://advizorpro.com/lead-scoring/'
    },
    {
      image: 'assets/img/login/private_wealth_teams_dataset.svg',
      url: 'https://advizorpro.com/private-wealth-team-data/'
    },
    {
      image: 'assets/img/login/ria_dataset.svg',
      url: 'https://advizorpro.com'
    }
  ];
  // Will hold the randomly chosen image and URL
  randomImage: { image: string; url: string };

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    let url = this.router.url.split('/');
    if (url && url[1] == 'unsubscribe') {
      this.unsubscribe = true;
    }

    let url_ap = this.router.url.split('?');
    if (url_ap && url_ap[1] == 'm=ap') {
      this.advPro = true;
    }

    const randomIndex = Math.floor(Math.random() * this.loginImages.length);
    this.randomImage = this.loginImages[randomIndex];
  }

}
