import { Routes } from "@angular/router";
import { CorporateLayout } from "./@pages/layouts";
import { AuthLayoutComponent } from "./@pages/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/auth.guard";
import { SearchNewModule } from "./advisor/search-new/search-new.module";
import { PublicLayoutComponent } from "./@pages/layouts/public/public-layout.component";
import { Page404Component } from "./page404/page404.component";
import { OpenRedirectResolver } from "./shared/resolvers/open-redirect.resolver";
import { RemoteAppLoader } from "./RemoteAppLoader";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        loadChildren: () =>
          import("./login/login.module").then((m) => m.LoginModule),
      },
      {
        path: "forgot-password",
        loadChildren: () =>
          import("./forgot-password/forgot-password.module").then(
            (m) => m.ForgotPasswordModule
          ),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("./resetpassword/resetpassword.module").then(
            (m) => m.ResetPasswordModule
          ),
      },
      {
        path: "signup",
        loadChildren: () =>
          import("./signup/signup.module").then((m) => m.SignupModule),
      },
      {
        path: "generate-password/:token",
        loadChildren: () =>
          import("./generate-password/generate-password.module").then(
            (m) => m.GeneratePasswordModule
          ),
      },
      {
        path: "unsubscribe/:id",
        loadChildren: () =>
          import("./unsubscribe/unsubscribe.module").then(
            (m) => m.UnsubscribeModule
          ),
      },
    ],
  },
  {
    path: "public",
    component: PublicLayoutComponent, // Optionally use a public layout
    children: [
      {
        path: "etf",
        loadChildren: () =>
          import("./public/etf/etf.module").then((m) => m.EtfModule),
      },
    ],
  },
  {
    path: "view/:id",
    resolve: {
      redirect: OpenRedirectResolver,
    },
    children: [],
  },
  {
    path: "",
    component: CorporateLayout,
    canActivate: [AuthGuard],
    children: [
      {
        path: "etf",
        component: RemoteAppLoader,
        data: {
          app: "etf",
        },
      },
      {
        path: "traffic-iq",
        component: RemoteAppLoader,
        data: {
          app: "traffic-iq",
        },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./profile/layout/layout.module").then((m) => m.LayoutModule),
      },
      {
        path: "search",
        loadChildren: () =>
          import("./search/search.module").then((m) => m.SearchModule),
      },
      {
        path: "crm/integrations",
        loadChildren: () =>
          import("./crm/integration/integration.module").then(
            (m) => m.IntegrationModule
          ),
      },
      {
        path: "salesforce",
        loadChildren: () =>
          import("./crm/integration/integration.module").then(
            (m) => m.IntegrationModule
          ),
      },
      {
        path: "upload",
        loadChildren: () =>
          import("./upload-investment/upload-investment.module").then(
            (m) => m.UploadInvestmentModule
          ),
      },
      {
        path: "report",
        // loadChildren: './upload-investment/upload-investment.module#UploadInvestmentModule'
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "my-data",
        loadChildren: () =>
          import("./myplan/myplan.module").then((m) => m.MyplanModule),
      },
      {
        path: "admin/users",
        loadChildren: () =>
          import("./admin/user/user.module").then((m) => m.UserModule),
      },
      {
        path: "admin/firm-logo-review",
        loadChildren: () =>
          import("./admin/firm-logo-review/firm-logo-review.module").then(
            (m) => m.FirmLogoReviewModule
          ),
      },
      {
        path: "admin/organizations",
        loadChildren: () =>
          import("./admin/organization/organization.module").then(
            (m) => m.OrganizationModule
          ),
      },
      {
        path: "admin/dashboard",
        loadChildren: () =>
          import("./admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "admin/reports",
        loadChildren: () =>
          import("./admin/report/admin-report.module").then(
            (m) => m.AdminReportModule
          ),
      },
      {
        path: "admin-view",
        loadChildren: () =>
          import("./admin-view/admin-view.module").then(
            (m) => m.AdminViewModule
          ),
      },
      {
        path: "automation",
        loadChildren: () =>
          import("./email-automation/email-automation.module").then(
            (m) => m.EmailAutomationModule
          ),
      },
      {
        path: "client/admin",
        loadChildren: () =>
          import("./admin/client/client.module").then((m) => m.ClientModule),
      },
      {
        path: "advisor/search",
        loadChildren: () =>
          import("./advisor/search-new/search-new.module").then(
            () => SearchNewModule
          ),
      },
      {
        path: "advisor/firm/:id",
        loadChildren: () =>
          import("./advisor/firm-profile-v2/firm-profile.module").then(
            (m) => m.FirmProfileModule
          ),
      },
      {
        path: "advisor/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "family/firm/:id",
        loadChildren: () =>
          import("./advisor/firm-profile-v2/firm-profile.module").then(
            (m) => m.FirmProfileModule
          ),
      },
      {
        path: "family/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "bank/firm/:id",
        loadChildren: () =>
          import("./advisor/firm-profile-v2/firm-profile.module").then(
            (m) => m.FirmProfileModule
          ),
      },
      {
        path: "bank/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "defined-contribution/firm/:id",
        loadChildren: () =>
          import("./advisor/firm-profile-v2/firm-profile.module").then(
            (m) => m.FirmProfileModule
          ),
      },
      {
        path: "defined-contribution/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "defined-benefit/firm/:id",
        loadChildren: () =>
          import("./advisor/firm-profile-v2/firm-profile.module").then(
            (m) => m.FirmProfileModule
          ),
      },
      {
        path: "defined-benefit/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "insurance/person/:id",
        loadChildren: () =>
          import("./advisor/person-profile-v2/person-profile.module").then(
            (m) => m.PersonProfileModule
          ),
      },
      {
        path: "score",
        loadChildren: () =>
          import("./score/score.module").then((m) => m.ScoreModule),
      },
      {
        path: "team/:id",
        loadChildren: () =>
          import("./team/team.module").then((m) => m.TeamModule),
      },
    ],
  },
  { path: "**", component: Page404Component },
  // {
  //   path: "**",
  //   redirectTo: "/not-found",
  //   pathMatch: "full"
  // }
];
