import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../environments/environment";

interface RemoteApp {
  load: (container: HTMLElement) => void;
}

declare global {
  interface Window {
    remoteApps: { [key: string]: RemoteApp };
  }
}

interface ManifestEntry {
  file: string;
  name?: string;
  src: string;
  isEntry?: boolean;
  css?: string[] | boolean;
}

interface Manifest {
  [key: string]: ManifestEntry;
}

@Component({
  selector: "app-remote-app-loader",
  template: "<div #container></div>",
})
export class RemoteAppLoader implements OnInit {
  app!: string;
  containerId: string = "remote-root";
  private baseUrl: string;
  private shadowRoot: ShadowRoot | null = null;

  constructor(private el: ElementRef, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      // Slug identifier for the app
      this.app = data["app"];

      // Unique identifier for the container
      this.containerId = `remote-root-${Math.random()
        .toString(36)
        .substring(2, 11)}`;

      // Create shadow DOM
      this.initializeShadowDOM();

      // Construct the base URL for the app
      this.baseUrl = environment.advizorProClientCDN;

      // Load the manifest
      this.loadAndMount();
    });
  }

  private initializeShadowDOM() {
    const container = this.el.nativeElement.querySelector("div");
    this.shadowRoot = container.attachShadow({ mode: "open" });

    // Create the container element for the remote app
    const remoteContainer = document.createElement("div");
    remoteContainer.id = this.containerId;
    remoteContainer.style.width = "100%";
    remoteContainer.style.height = "100%";
    remoteContainer.style.marginTop = "20px";
    remoteContainer.classList.add("remote-app-container");

    this.shadowRoot.appendChild(remoteContainer);
  }

  private async loadAndMount() {
    if (!this.app) {
      console.error("App slug is required");
      return;
    }

    await this.loadRemoteApp();
    await this.mountRemoteApp();
  }

  private async loadRemoteApp() {
    if (this.isRemoteAppLoaded()) {
      return;
    }

    try {
      const response = await fetch(`${this.baseUrl}/.vite/manifest.json`);
      const manifest: Manifest = await response.json();

      const entryPoint = Object.values(manifest).find(
        (entry) => entry.isEntry && entry.name === this.app
      );

      if (!entryPoint) {
        throw new Error(`No entry point found for app: ${this.app}`);
      }

      // TODO: Optimize this via a recursive lookup from the entry point
      // Collect all unique CSS files from all entries
      const cssFiles = new Set<string>();
      Object.values(manifest).forEach((entry) => {
        if (entry.css && Array.isArray(entry.css)) {
          entry.css.forEach((cssFile) => cssFiles.add(cssFile));
        }
      });

      // Load all CSS files into shadow DOM
      await Promise.all(
        Array.from(cssFiles).map((cssFile) =>
          this.loadCssIntoShadowDOM(`${this.baseUrl}/${cssFile}`)
        )
      );

      await this.loadScript(`${this.baseUrl}/${entryPoint.file}`);
    } catch (error) {
      console.error("Failed to load remote app:", error);
    }
  }

  private loadCssIntoShadowDOM(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      link.onload = () => resolve();
      link.onerror = () => reject(new Error(`Failed to load CSS: ${url}`));
      this.shadowRoot?.appendChild(link);
    });
  }

  private async mountRemoteApp() {
    if (this.isRemoteAppLoaded()) {
      window.remoteApps[this.app].load(
        this.shadowRoot?.getElementById(this.containerId)
      );
    } else {
      console.error(
        "Remote app not found or did not expose a `load` function."
      );
    }
  }

  private loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.type = "module";
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
      document.body.appendChild(script);
    });
  }

  private isRemoteAppLoaded() {
    if (window && window.remoteApps && window.remoteApps[this.app]) {
      return true;
    }
    return false;
  }
}
