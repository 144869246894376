export const environment = {
  slug: "production",
  production: true,
  baseUrl: "https://apisl.planprofessional.com",
  versionCheckURL: "https://app.advizorpro.com/version.json",
  office365ClientId: "149c2dc4-c89f-4523-959d-8ee9cf862cb8",
  mapboxAccessToken:
    "pk.eyJ1IjoiaGVzb21wIiwiYSI6ImNsemJkODZwejAzMmgyanE4eDVjODEwdDkifQ.xOLZnxcoD4vTwZFK6pPe5w",
  advizorProClientCDN: "https://cdn.client.advizorpro.com",
  advizorProApi: "https://app.api.advizorpro.com"
};
