import { Component, OnInit, ViewChild, Input, ChangeDetectorRef,ViewContainerRef, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map } from 'rxjs/operators';
import { Subject } from "rxjs/internal/Subject";
import { Observable, fromEvent } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from "underscore";
import { AdvizorProService } from '../../services/advizor-pro.service';
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/report.service';
import { Auth } from '../../services/auth.service';
import { IntegrationService } from '../../../shared/services/integration.service';
import { ModalService } from '../../services/modal.service';
import { SyncModalComponent } from '../../../shared/components/sync-modal/sync-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AdvisorProfile, User } from './reference';

import { Dba } from "../../interfaces/dba.interface"
import { DbaInPerson } from "../../interfaces/person.interface"
import { DEFINED_BENEFIT, DEFINED_CONTRIBUTION } from '../advisor_firm_modal/core';
import { ToolTipMessage } from '../../../advisor/search-new/reference-tooltip';
import { getIconsByRowKeys, getIconsDataset } from '../../utils/icon-utils';
import { Icon } from '../icon-dataset/icons';
import { Disclosure, PersonDisclosureResponse } from './person-disclosures/person-disclosures-interface';

interface Paginate {
  pageNumber: number;
  limit: number;
}

interface PaginateWithSort extends Paginate {
  sortField?: string;
  sortOrder?: string;
}

@Component({
	selector: 'advisor-person-modal',
	templateUrl: './advisor_person_modal.component.html',
	styleUrls: ['./advisor_person_modal.component.scss'],
	providers: []
})
export class AdvisorPersonModalComponent implements OnInit {
    @Input() profileData: { type: string, sub_type: string, crd: string | number, score?: string };
    crd: string | number;
    npn: string | number;
    private profileObserver: Observable<Object>;
    personProfile: any;
    // personProfile: typeof AdvisorProfile;
    personDisclosures: Disclosure[] = []
    personLicenseLimit = [];
    paginate: Paginate = { pageNumber: 0, limit: 10 };
    paginateApt: PaginateWithSort = { pageNumber: 0, limit: 10 };
    paginateTeam: Paginate = { pageNumber: 0, limit: 10 };
    columnModeSetting = (window.innerWidth < 960) ? 'standard':'force';
    scrollBarHorizontal = (window.innerWidth < 960);
    attributes: Record<string, { value: string }[]> = {};
    linkedin: string[] = [];
    main_url: string[] = [];
    personFirmHistory: { data: Record<string, unknown>[], total: number } = { data: [], total: 0 };
    personHasOwnership = false;
    personInsuranceCarriers: { carrier: string }[] = [];
    personInsuranceLines: { line: string }[] = [];
    personInsuranceAppointments: { data: Record<string, unknown>[], total: number } = { data: [], total: 0 };
    user: User;
    insurances = false;
    teams: Record<string, unknown>[] = [];
    score: string;
    personTitle: string[] = [];
    person_registered_state: { per_page: number, current_page: number, data: Record<string, unknown>[], total?: number, more_page?: number } = { per_page: 5, current_page: 1, data: [] };
    personRegisteredStateLimit = [];
    iaProfile: string;
    brokerProfile: string;

    @ViewChild('apt_search', { static: false }) aptSearch!: ElementRef;
    @ViewChild('limit_over_model') limit_over_model: ModalDirective;
    downloadMessage = { status_message: 'Your report is processing...', status: 1 };
    percent_complete = 0;
    isDownload = false;
    exportStatus = false;
    insuraceAppointMentLoad = false;
    onlyTeamAccess = false;
    profileType = '';
    // profile_url:any = [];
    appointmentSearch: string = '';
    personInsuranceCarriersLimit: { carrier: string }[];
    personInsuranceLinesLimit: { line: string }[];
    isInsuranceCarrier = true;
    isInsuranceLine = true;

    messagesFirm = {
        emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Loading...<\/div>',
    }
    dbaData: Dba;
    search_type: string = '';
    private subject: Subject<string> = new Subject();
    team_search_keyword = '';


    // for a follow button
    advisorFollow = false;
    advisor_follow_type_model = false;
    advisorFollowData: { crd?: string | number, type?: string, follow_type?: string, status?: string } = {};
    advisor_follow_status: string = '';
    advisor_user_follow_type = 'private';
    advisorFollowTitle = 'Follow ';
    advisorStatus = '';
    advisorType: [];
    href: string;
    statusList = ['Cold Lead', 'Warm Lead', 'Hot Lead', 'Client', 'Closed Lost'];

    // for a hubspot and salesforce access
    tech_stack_modal = false;
    hubspotAccess = false;
    salesforceAccess = false;
    advizorPdf = '';
    salesforceImportData = false;
    hubspotImportData = false;
    person_id: string | number;
    person_tags_length  = false;
    firm_tags_length  = false;
    advisor_firm_tag_model  = false;
    paginateLimits: number[] = [5, 10, 20];
    licences_more = false;
    registration_more = false;
    carrier_more = false;
    personInsurenceCarrierData: { carrier: string }[] = [];
    line_more  = false;
    licence_modal  = false;
    registration_modal  = false;
    carrier_modal  = false;
    line_modal  = false;
    accolades : { keyword_display: string; color: string; level: string; url?: string }[] = [];
    alma_mater: { keyword_display: string; color: string; level: string; url?: string }[] = [];
    bank_trust_team_types: {label: string, key: string}[] = [];
    public readonly dbaContactId: string = 'dbaContactPerson';

    protected realPersonId: number | null = null
    toolTipOrnament: typeof ToolTipMessage;
    tooltipColor: string;
    previousWidth: number;
    resizeTimeout: ReturnType<typeof setTimeout> = null;
    toolTipIconOrnament: Record<string, string> = {};

    constructor(
        public advizorProService: AdvizorProService,
        public activatedRoute: ActivatedRoute,
        public auth: Auth,
        public userService: UserService,
        public reportService: ReportService,
        public integrationService: IntegrationService,
        public router: Router,
        private cdr: ChangeDetectorRef,
        private modal: NzModalService,
        private viewContainerRef: ViewContainerRef,
        private readonly modalService: ModalService
    ) {
        this.paginate.pageNumber = 0;
        this.paginate.limit = 10;

        this.paginateTeam.pageNumber = 0;
        this.paginateTeam.limit = 10;

        this.paginateApt.pageNumber = 0;
        this.paginateApt.limit = 10;

        this.tooltipColor = '#000';
        
        this.advizorProService.$advTooltip.subscribe(value => {
            if (value) {
                this.toolTipOrnament = value as unknown as typeof ToolTipMessage
                this.toolTipIconOrnament = getIconsDataset(value as Record<string, string>);
            }
        });

        window.onresize = () => {
            this.scrollBarHorizontal = (window.innerWidth < 960);
            this.columnModeSetting = (window.innerWidth < 960) ? 'standard':'force';
        };
	}
    
    ngOnInit() {
        this.previousWidth = window.innerWidth;
        this.scrollBarHorizontal = (this.previousWidth < 960);
        this.columnModeSetting = (this.previousWidth < 960) ? 'standard' : 'force';

        if (this.profileData) {
            this.user = this.auth.getUser();

            //if Team Access
            if (this.user.has_access_team) {
                this.onlyTeamAccess = true;
            }
        
            if (this.user.salesforce) {
                this.salesforceAccess = this.user.salesforce
            }
        
            if (this.user.hubspot) {
              this.hubspotAccess = this.user.hubspot;
            }

            this.profileType = this.profileData.type;
            this.score = this.profileData?.score; 
            this.crd = this.profileData.crd;
            this.getPersonProfile(this.profileType);
            this.getPersonRegistered();
            this.getPersonDisclosures();

            this.followbuttonLoad();
            this.checkAdvpdfType();
        }
    }

    ngAfterContentChecked() {
        const newWidth = window.innerWidth;
        if (this.previousWidth !== newWidth) {
            this.previousWidth = newWidth;
            this.scrollBarHorizontal = (newWidth < 960);
            this.columnModeSetting = (newWidth < 960) ? 'standard' : 'force';

            // Debounce the resize event dispatch
            if (!this.resizeTimeout) {
                this.resizeTimeout = setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    this.cdr.detectChanges();
                    this.resizeTimeout = null; // Clear timeout after use
                }, 100);  // Adjust timeout value if needed
            }
        }
    }

    onKeyUp(searchTextValue: KeyboardEvent) {
        const target = searchTextValue.target as HTMLInputElement;
        this.team_search_keyword = target.value;
        this.subject.next(this.team_search_keyword);
    }

    ngAfterViewInit() {
        this.subject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.getTeams();
        });
    }

    hasBrokerFirm(): boolean {
        return !!this.personProfile?.broker || (this.personProfile?.advizor_person_firms ?? []).some(advizor_person_firm => !!advizor_person_firm?.broker);
    }

    hasRIAFirm(): boolean {
        return !!this.personProfile?.advizor || (this.personProfile?.advizor_person_firms ?? []).some(advizor_person_firm => advizor_person_firm?.advizor);
    }

    hasBankTrustFirm(): boolean {
        return !!this.personProfile?.bt || (this.personProfile?.advizor_person_firms ?? []).some(advizor_person_firm => advizor_person_firm?.bt);
    }

    getPersonProfile(type: string) {
        switch (type) {
            case 'advisor':
                this.profileObserver = this.advizorProService.getPersonProfile(this.crd);
                break
            case 'family':
                this.profileObserver = this.advizorProService.getFamilyPersonProfile(this.crd);
                break
            case 'bank':
                this.profileObserver = this.advizorProService.getBankAndTrustPersonProfile(this.crd);
                break
            case 'bank_person':
                this.profileObserver = this.advizorProService.getBankAndTrustPersonProfile(this.crd);
                break
            case DEFINED_BENEFIT:
            case DEFINED_CONTRIBUTION:
                this.profileObserver = this.advizorProService.getPersonProfileByType(type, this.crd)
                break
            default:
                this.profileObserver = this.advizorProService.getInsurancePersonProfile(this.crd);
                break
        }

        return this.profileObserver.subscribe(
            (response: { data: typeof AdvisorProfile, status: number }) => {
                if (response.data && response.status == 200) {
                    const profile: typeof AdvisorProfile = response.data
                    this.personProfile = profile;
                    this.realPersonId = profile.id
                    
                    if(this.hasBankTrustFirm() && this.user.has_access_bank_and_trust) {
                        this.bank_trust_team_types = [
                            { label: 'Investment Committee', key: 'investment_team' },
                            { label: 'Trust Team', key: 'trust' },
                            { label: 'Wealth Management Team', key: 'wealth_management' }
                        ];
                    }
                    this.score = profile.score;
                    this.npn = profile.npn;
                    this.search_type = '0';
                    if (this.personProfile?.advizor_dba_team?.length > 0) {
                        this.search_type = '1';
                    }

                    let finalArr = profile.person_tags.reduce((result, { tag_category, ...rest }) => {
                        let group = result.find(g => g.tag_category === tag_category);

                        const restData = { ...rest } as unknown as { keyword_display: string; color: string; level: string; url?: string };

                        if (!group) {
                            group = { tag_category, firm_tags: [], person_tags:[] };
                            result.push(group);
                        }

                        if (tag_category == 'School') {
                            this.alma_mater.push({...restData});
                        }

                        if (tag_category == 'Accolades & Awards') {
                            this.accolades.push(restData);
                        }

                        if (rest.level != 'person') {
                            group.firm_tags.push({ tag_category, ...restData });
                            this.firm_tags_length = true
                        } else {
                            group.person_tags.push({tag_category, ...restData});
                            this.person_tags_length = true;
                        }

                        return result;
                    }, []);

                    console.log('finalArr', finalArr);
                    

                    //remove Custodian and Platform from tags
                    finalArr = finalArr.filter(item => item.tag_category !== 'Accolades & Awards' && item.tag_category !== 'Custodian');

                    this.personProfile.person_tags = finalArr;
                    // console.log('this.personProfile.person_tags', this.personProfile.person_tags); return;
                    this.personTitle = this.personProfile.person_title;
                    let personAddress = [];

                    if (profile.full_address) {
                        let addr = profile.full_address;

                        if (addr.address_1){
                            personAddress.push(addr.address_1);
                        }

                        if (addr.city){
                            personAddress.push(addr.city);
                        }

                        if (addr.state){
                            personAddress.push(addr.state);
                        }

                        if (addr.zip) {
                            personAddress.push(addr.zip);
                        }
                    }

                    this.personProfile.address = personAddress;

                    profile.person_attribute.forEach(item => {
                        this[item.attribute.slug] = item;
                        if (this.attributes.hasOwnProperty(item.attribute.slug)) {
                            this.attributes[item.attribute.slug].push(item);
                        } else {
                            var array = [];
                            array.push(item);
                            this.attributes[item.attribute.slug] = array;
                        }
                    });

                    if (profile.insurance){
                        this.user.user_access.forEach(item => {
                            if (item.module_id == 4) {
                                this.insurances = true;
                                this.getPersonInsuranceCarriers();
                                this.getPersonInsuranceLines();
                                this.getPersonInsuranceAppointments();
                            }
                        });
                    }

                    this.getPersonInsight();
                    this.getTeams();
                    this.getPersonFirmHistory();

                    if (this.personProfile.crd) {
                        this.iaProfile = "https://adviserinfo.sec.gov/individual/summary/" + this.personProfile.crd;
                        this.brokerProfile = "https://brokercheck.finra.org/individual/summary/" + this.personProfile.crd;
                    }

                    this.personProfile.logo_name = this.personNameFilter(this.personProfile);

                    if (this.personProfile?.person_licenses_registrations?.length > 0) {
                        this.personLicenseLimit = this.personProfile.person_licenses_registrations.slice(0,2);
                    }
                }
            },
            (_err: unknown) => {
                console.error("opening person profile.", _err)
                // skipcq: JS-0328
                this.router.navigate(["not-found"])
            },
        );
    }

    getPersonDisclosures(){
        const crdNumber = Number(this.crd);
        if (!Number.isInteger(crdNumber) || crdNumber <= 0) 
            return; 
        
        this.advizorProService.getPersonDisclosures(crdNumber).subscribe({
            next: (response) => {
                this.personDisclosures  = (response as PersonDisclosureResponse).data
            }
        })
    }

    personNameFilter(row) {
        if (row) {
            return row.first_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0)+row.last_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0);
        }
    }

    getPersonFirmHistory(){
        const args = {
            limit: this.paginate.limit,
            page: this.paginate.pageNumber + 1,
            crd: this.crd
        }
		return this.advizorProService.getPersonFirmHistory(args).subscribe(
            (data: { data: { data: Record<string, unknown>[], total: number } }) => {
                if (data) {
                    this.personFirmHistory = data.data;
                    if (data?.data.total) {
                        // this.setMenuObj('person', '#firm_history', {id:"#firm_history", label:'Firm History'});

                        for(let r of this.personFirmHistory.data){
                            if (r.ownership != null){
                                this.personHasOwnership = true;
                            }
                        }

                    }
                }
            },
        );
    }

    getPersonInsuranceCarriers(){
        const args = {
            limit: this.paginate.limit,
            page: this.paginate.pageNumber + 1,
            npn: this.npn
        }
        return this.advizorProService.getPersonInsuranceCarriers(args).subscribe(
            (data: { data: { carrier: string }[] }) => {
                if (data) {
                    this.personInsuranceCarriers = data.data;
                    this.personInsuranceCarriers.forEach((value) => {
                        if (value.carrier) {
                            this.personInsurenceCarrierData.push(value);
                        }
                    })

                    this.personInsuranceCarriersLimit = _.first(data.data, 2);
                }
            },
        );
    }

    getPersonInsuranceLines(){
        const args = {
            limit: this.paginate.limit,
            page: this.paginate.pageNumber + 1,
            npn: this.npn
        }
        return this.advizorProService.getPersonInsuranceLines(args).subscribe(
            (data: { data: { line: string }[] }) => {
                if (data) {
                    this.personInsuranceLines = data.data;

                    this.personInsuranceLinesLimit = _.first(data.data, 2);
                }
            },
        );
    }

    getPersonInsuranceAppointments() {
        const args = {
            limit: this.paginateApt.limit,
            page: this.paginateApt.pageNumber + 1,
            sortField: this.paginateApt.sortField,
            sortOrder: this.paginateApt.sortOrder,
            search: this.appointmentSearch,
            npn: this.npn,
            is_export: 0
        };

        return this.advizorProService.getPersonInsuranceAppointments(args).subscribe(
            (data: { data: { data: Record<string, unknown>[], total: number } }) => {
                if (data) {
                    this.personInsuranceAppointments = data.data;

                    /* if (this.personInsuranceAppointments.total) {

                        this.setMenuObj('person', '#insurance_appointments', {id:"#insurance_appointments", label:'Appointments'});
                        this.setMenuObj('insurance', '#insurance_appointments', {id:"#insurance_appointments", label:'Appointments'});
                    } */

                    if (!this.insuraceAppointMentLoad && this.aptSearch) {
                        this.insuraceAppointMentLoad = true;
                        fromEvent<KeyboardEvent>(this.aptSearch.nativeElement, 'keyup')
                        .pipe(
                            map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
                            debounceTime(500),
                        ).subscribe((val: string) => {
                            this.paginateApt.pageNumber = 0;
                            this.paginateApt.limit = 20;
                            this.appointmentSearch = val;
                            this.getPersonInsuranceAppointments();
                        });
                    }

                }
            },
        );
    }

    exportModel() {
        this.userService.userReportCount().subscribe((data: { advisor_excel_count: number }) => {
            if (!this.user.ap_user_limit_csv || (this.user.ap_user_limit_csv && this.user.ap_user_limit_csv <= data.advisor_excel_count)) {
              this.limit_over_model.show();
              return false;
            }

            this.downloadMessage = { status_message: 'Your report is processing...', status: 1 };
            this.percent_complete = 0;

            this.exportStatus = true;
            this.isDownload = false;
            this.advizorProService.getPersonInsuranceAppointments({
                npn: this.npn,
                search: this.aptSearch.nativeElement.value,
                limit: this.paginateApt.limit,
                page: this.paginateApt.pageNumber + 1,
                sortField: this.paginateApt.sortField,
                sortOrder: this.paginateApt.sortOrder,
                is_export: 1
            }).subscribe((res: { code: number, errors?: string, data?: { link?: string } }) => {
                if (res.code === 400 && res.errors === 'Your download limit is exceed.') {
                    this.exportStatus = false;
                    this.limit_over_model.show();
                    return false;
                }
                if (res.data?.link) {
                    this.exportStatus = false;
                    window.open(res.data.link);
                }
            });
        })
    }

    teamSearchChange() {
        this.getTeams();
    }

    getTeams() {
        const searchTeam = {
            limit: this.paginateTeam.limit,
            page: this.paginateTeam.pageNumber + 1,
            crd: this.crd,
            search: this.team_search_keyword,
            advizor_firm_dba_id: (this.search_type == '1') ? this.personProfile?.advizor_dba_team[0].dba_id : ''
        };
        return this.advizorProService.personTeam(searchTeam).subscribe(
            (data: { data: Record<string, unknown>[] }) => {
            if (data) {
                this.teams = data.data;
            }
            },
        );
    }

    sortData($event: { page: number }) {
        this.paginateTeam.pageNumber = $event.page - 1;
        this.getTeams();
    }

    pageChange($event: { page: number }) {
        this.paginate.pageNumber = $event.page - 1;
        this.getPersonFirmHistory()
    }

    limitPageChange($event: { target: { value: string } }) {
        let limit = parseInt($event.target.value)
		this.paginate.limit = limit;
		this.getPersonFirmHistory();
    }

    aptPageChange($event: { page: number }) {
        this.paginateApt.pageNumber = $event.page - 1;
        this.getPersonInsuranceAppointments();
    }

    onAptSort($event) {
        this.paginateApt.sortField = $event.sorts[0].prop;
        this.paginateApt.sortOrder = $event.sorts[0].dir;
        this.paginateApt.pageNumber = 0;
        this.getPersonInsuranceAppointments();
    }

    attribute(attribute, type=null) {
        if (!this.attributes[attribute]) {
            return null;
        }

        if (type == 'single') {
            return this.attributes[attribute][0]['value'];
        } else if (type == 'comma') {
            let returnval = [];
            this.attributes[attribute].forEach(item => {
                if (returnval.indexOf(item.value) == -1) {
                    returnval.push(item.value);
                }
            });
            return returnval.join(', ');
        } else if (type == 'commaToArray') {
            return this.attributes[attribute][0]['value'].split(',');
        } else {
            let returnval = [];
            this.attributes[attribute].forEach(item => {
                if (returnval.indexOf(item.value) == -1) {
                    returnval.push(item.value);
                }
            });
            return returnval;
        }
    }



    sortPersonRegisteredData($event) {
        this.person_registered_state.current_page = $event.offset+1;
        this.getPersonRegistered();
    }

	getPersonRegistered() {
        if (this.person_registered_state?.per_page == this.person_registered_state?.total) {
            return false;
        }
        if (this.person_registered_state?.more_page) {
            this.person_registered_state.per_page = this.person_registered_state?.more_page
        }
        this.advizorProService.getPersonRegistered(this.crd, this.profileType, this.person_registered_state).subscribe(
            (res: any) => {
                this.person_registered_state = res.data;
                if (res?.data?.data?.length > 0) {
                    this.personRegisteredStateLimit = _.first(res.data.data, 2);
                }
			});
	}

    phoneType(type) {
        let formatStr = '';
        if (type) {
            type = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase();
            formatStr = '(' + type+')';
        }
        return formatStr;
    }

    openDbaContact(dba: DbaInPerson) {
        if(!dba) return;

        const dbaData: Dba = {
                ...dba?.team,
                firm_name: dba?.team?.firm?.firm_name
        }

        this.dbaData = dbaData;
        this.modalService.openModal(this.dbaContactId)
    }

    followAdvizor() {
        if (this.user.account_number && !this.advisorFollow) {
          this.advisor_follow_type_model = true;
        } else {
          this.followAdvizorUpdate();
        }
    }

    followAdvizorUpdate() {
          this.advisorFollowData.crd = this.crd;
          this.advisorFollowData.type = (this.profileData.sub_type == 'insurance') ? 'in-person' : 'person';

        this.advizorProService.followAdvizor(this.advisorFollowData).subscribe((response: { response: string, status?: string }) => {

          if (response.response === 'unfollow') {
            this.advisor_follow_status = null;
            this.advisor_user_follow_type = 'private';
            this.advisorFollow = true;
            this.advisorFollowTitle = 'Unfollow ' + this.titleCaseWord(this.advisorFollowData.type);
          } else {
            this.advisorStatus = '';
            this.advisorFollow = false;
            this.advisorFollowTitle = 'Follow ' + this.titleCaseWord(this.advisorFollowData.type);
          }
        });
    }

    titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    followbuttonLoad() {
        this.advisorFollowStatus(this.crd, 'person');

        if (this.profileData.sub_type == 'insurance'){
            this.getSalesforceImportedData(null, this.crd);
            this.getHubspotImportedData(null, this.crd);
        } else {
            this.getSalesforceImportedData(this.crd, null);
            this.getHubspotImportedData(this.crd, null);
        }
    }

    advisorFollowStatus(crd, type) {
        this.advizorProService.followStatus(crd, type).subscribe((response: { response: { follow_status: string, status?: string } }) => {
          if (response.response.follow_status === 'unfollow') {
            this.advisorFollowTitle = 'Unfollow ' + this.titleCaseWord(type);
            this.advisorFollow = true;
            if (response.response.status) {
              this.advisorStatus = response.response.status;
            }
          } else {
            this.advisorFollow = false;
            this.advisorFollowTitle = 'Follow ' + this.titleCaseWord(type);
          }
        });
    }

    advFollowTypeContentCancel() {
        this.advisor_follow_type_model = false;
    }

    onAdvisorFollowType() {
        this.advisor_follow_type_model = false;
        this.advisorFollowData.follow_type = this.advisor_user_follow_type;
        this.advisorFollowData.status = this.advisor_follow_status;
        this.advisorStatus = this.advisor_follow_status;
        this.followAdvizorUpdate();
    }

    checkAdvpdfType() {
        if (this.profileType == 'insurance' && this.profileData.sub_type == 'person') {
            this.person_id = this.crd;
            this.npn = this.crd;
            this.advizorPdf = 'in-person';
        } else if(this.profileData.sub_type == 'person'){
            this.person_id = this.crd;
            this.crd = this.crd;
            this.advizorPdf = 'person';
        }
    }

    importContact(type, adv_type) {
        const initialState = {
          list: {
            "firm_total": 0,
            "person_total": 0,
            "type": type,
            "adv_type": adv_type,
            "npn": this.npn,
            "crd": this.profileType != 'family' ? this.crd : '',
            "firm_crd": '',
            "search": '',
            "firm_fo_id": '',
            "person_fo_id": this.profileType == 'family' ? this.crd : '',
          }
        };

        const modal = this.modal.create<SyncModalComponent>({
          nzContent: SyncModalComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzComponentParams: initialState,
          nzOnOk: () => new Promise(resolve => {
            setTimeout(resolve, 200);
          })
        });

        modal.componentInstance.changeStatus.subscribe((res) => {
          if (res.type == 'salesforce') {
            this.salesforceImportData = true;
          } else {
            this.hubspotImportData = true;
          }
        })
    }

    getSalesforceImportedData(crd, npn) {
        this.integrationService.getImportData('salesforce', crd, npn).subscribe((response: { response: boolean }) => {
          if (response.response){
            this.salesforceImportData = true;
          }
        });
      }

    getHubspotImportedData(crd, npn) {
        this.integrationService.getImportData('hubspot', crd, npn).subscribe((response: { response: boolean }) => {
          if (response.response){
            this.hubspotImportData = true;
          }
        });
    }

    advFirmTag() {
        this.advisor_firm_tag_model = true;
    }

    advFirmTagCancel() {
        this.advisor_firm_tag_model = false;
    }

    seeMoreLicence() {
        this.licence_modal = true;
        // this.licences_more = true;
    }

    licenceModalCancel() {
        this.licence_modal = false;
    }

    seeMoreRegisterations() {
        this.person_registered_state.more_page = this.person_registered_state.total;
        this.getPersonRegistered();
        setTimeout(() => {
            this.registration_modal = true;
        }, 150);
    }

    registrationModalCancel() {
        this.registration_modal = false
    }

    seeMoreCarrier() {
        this.carrier_modal =  true;
    }

    carrierModalCancel() {
        this.carrier_modal =  false;
    }

    seeMoreLines() {
       this.line_modal = true;
    }

    lineModalCancel() {
        this.line_modal = false;
    }

    stringLength(value) {
        if (!value) {
            return '';
        }
        return value.length <= 15 ? value : value.substring(0, 15) + '...';
    }

    protected insights: { id: number, insight: string }[] = [];
    protected insights_limit: { id: number, insight: string }[] = [];

    getPersonInsight() {
        this.advizorProService.personInsight(this.realPersonId).subscribe(
            (response: { data: { id: number, insight: string }[] }) => {
                if (response) {
                    this.insights = response.data;
                    this.insights_limit = response.data.slice(0, 4);
                }
            },
        );
    }

    handleRoute(row) {
        const url = this.router.serializeUrl(this.router.createUrlTree([row]));
        window.open(url, '_blank');
    }

    getIcons(row: Record<string, string>): Icon[] {
		return getIconsByRowKeys(row);
	}
}

