export const SEARCH_VARIABLES: Record<string, string> = Object.freeze({
  bulk_firm_city: "bulk_firm_city",
  bulk_ticker: "bulk_ticker",
  bulk_firm_zip: "bulk_firm_zip",
  bulk_person_city: "bulk_person_city",
  bulk_person_zip: "bulk_person_zip",
  bulk_team_zip: "bulk_team_zip",
  bulk_team_city: "bulk_team_city",
  upload_person_crd: "upload_person_crd",
  upload_crd: "upload_crd",
  // Teams
  team_member: "team_member",
  // Plans
  plan_type: "plan_type",
  plan_insights_red_flags: "plan_insights_red_flags",
  plan_multiple_employer_plan: "plan_multiple_employer_plan",
  plan_my_plans: "plan_my_plans",
  plan_plans_with: "plan_plans_with",
  //TrafficIQ
  firm_traffic_iq_date_range_options: "firm_traffic_iq_date_range_options",
  firm_traffic_iq_date_range: "firm_traffic_iq_date_range",
  firm_traffic_iq_url: "firm_traffic_iq_url",
  firm_traffic_iq_sources: "firm_traffic_iq_sources",
  firm_traffic_iq_keywords: "firm_traffic_iq_keywords",
  firm_traffic_iq_website_visitors: "firm_traffic_iq_website_visitors",
  person_traffic_iq_date_range_options: "person_traffic_iq_date_range_options",
  person_traffic_iq_date_range: "person_traffic_iq_date_range",
  person_traffic_iq_url: "person_traffic_iq_url",
  person_traffic_iq_sources: "person_traffic_iq_sources",
  person_traffic_iq_keywords: "person_traffic_iq_keywords",
  person_traffic_iq_website_visitors: "person_traffic_iq_website_visitors",
});
