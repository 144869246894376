import { ChangeDetectorRef, Component, OnInit, ViewChild, Input,ViewContainerRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map } from 'rxjs/operators';
import { fromEvent, Subscription, interval } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { AdvizorProService } from '../../services/advizor-pro.service';
import { ReportService } from '../../services/report.service';
import { UserService } from '../../services/user.service';
import { Auth } from '../../services/auth.service';
import { ProfileService } from '../../../shared/services/profile.service';
import { ModalService } from '../../services/modal.service';
import { SyncModalComponent } from '../../../shared/components/sync-modal/sync-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Dba, DbaResponse, DbaData  } from "../../interfaces/dba.interface"
import { DEFINED_BENEFIT, DEFINED_CONTRIBUTION } from './core';
import { ToolTipMessage } from '../../../advisor/search-new/reference-tooltip';
import { getIconsByRowKeys, getIconsDataset } from '../../utils/icon-utils';
import { Icon } from '../icon-dataset/icons';

@Component({
	selector: 'advisor-firm-modal',
	templateUrl: './advisor_firm_modal.component.html',
	styleUrls: ['./advisor_firm_modal.component.scss'],
	providers: []
})
export class AdvisorFirmModalComponent implements OnInit {

    @Input() profileData:any;
    paginate: any = [];
    paginateOffice: any = [];
    paginateHolding: any = [];
    paginateLicensed: any = [];
    paginateFirmDba: any = [];
    paginateFirmOwners: any = [];
    paginateFirmContact: any = [];
    // paginateFamFirm: any = [];
	investmentAdvisor:any;
	broker:any;
	firmProfile:any;
    columnModeSetting = (window.innerWidth < 960) ? 'standard':'force';
    scrollBarHorizontal = (window.innerWidth < 960);
    firm_id:any;
    crd:any;
    attributes:any = [];
    main_url:any = [];
    twitter:any = [];
    facebook:any = [];
    instagram:any = [];
    brochures:any = [];
    linkedin:any = [];
    trust_page:any = [];
    user: any;
    /* follow: boolean = false;
    title: any = 'Follow Firm'; */

    isNumber = (n: string | number): boolean =>
        !isNaN(parseFloat(String(n))) && isFinite(Number(n));
    // contacts: any;
    contacts: any = { per_page: 5, current_page: 1, data: [] };
    contactSearchAfter:any = [];
    ownerContacts: any;
    familyContactData: any;
    offices: any;
    advizorFirmDba: DbaData;
    holdings: any;
    filling: any;
    licensed: any;
    planProAccess = true;
    firm_web = false;
    adv_model = false;
    limit_over_model = false;
    waitingBox = false;
    temp = [];
    exportHoldStatus = false;
    exportTeamStatus = false;
    excel_hold_link = '';
    isHoldDownload = false;
    holdingListStatus = true;
    exportStatus = false;
    excel_link = '';
    excel_office_link = '';
    isDownload = false;
    isTeamDownload = false;
    // isAdvisorContactData: boolean = false;
    contactExport: Subscription;
    iaProfile: any;
    brokerProfile: any;
    // user_follow_type: any = 'private';
    // followData:any = {};

    slideUp: any = {
        type: "md"
    }
    holdingDates = [];
    holding_date:any = '';
    // badgeData = [];

    downloadMessage = { status_message: 'Your report is processing...', status: 1 };
    percent_complete = 0;
    aumTotalChart: any = {};
    aumAccountChart: any = {};
    exportOfficeStatus: boolean;
    firm_diff: any = [];
    firmAttributes: any = [];
    insights: any = [];
    // employeeContact:boolean = false;
    messagesFirm = {
        emptyMessage: '<div class=\"mt-3 ml-3 bold\" align=\"center\">Loading...<\/div>',
    }
    dbaData: Dba;
    profileType:any;
    method:any;
    onlyTeamAccess = false;
    teamHide = false;
    portfolioCompaniesHide = false;
    
    private subject: Subject<string> = new Subject();
    private subjectHoldingSearch: Subject<string> = new Subject();
    private subjectFirmOffice: Subject<string> = new Subject();
    team_search_keyword = '';
    holding_search_keyword = '';
    paginateLimits:any= [10,20,50];
    paginateFirmOwnersLimits:any= [3,6,9,12,15];
    paginateContactListLimits:any= [9,18,27];

    advisorFollow = false;
    advisor_follow_type_model = false;
    advisorFollowData: any = {};
    advisor_follow_status:any = '';
    advisor_user_follow_type:any = 'private';
    advisorFollowTitle = 'Follow ';
    advisorStatus = '';
    href: string;
    follow: any = {};
    follows_data: boolean;
    follow_status:any;
    statusList = ['Cold Lead', 'Warm Lead', 'Hot Lead', 'Client', 'Closed Lost'];
    advisor_firm_tag_model = false;
    currentDate: Date = new Date();
    platforms:any = [];
    tech_stack_modal = false;
    hubspotAccess = false;
    salesforceAccess = false;
    advizorPdf = '';
    salesforceImportData = false;
    hubspotImportData = false;
    smaPieChart: any = {};
    profile_url: string[];
    @ViewChild('contact_search') contactSearch: any;
    insights_limit: any = [];
    contactPage = 0;
    contact_search_keyword: string;
    currentContactPage = 1;
    totalContactPages = 1;
    currentDbaPage = 1;
    totalDbaPages = 1;
    public readonly dbaContactId: string = 'dbaContactFirm';
    toolTipOrnament: any;
    tooltipColor: string;
    previousWidth: number;
    resizeTimeout: ReturnType<typeof setTimeout> = null;
    toolTipIconOrnament: any;
    smaPieLegendChart: any = {};
    trafficIqAccess = false;

    constructor(
        public advizorProService: AdvizorProService,
        public reportService: ReportService,
        public userService: UserService,
        public profileService: ProfileService,
	    public auth: Auth,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        private cdr: ChangeDetectorRef,
        private modal: NzModalService,
        private viewContainerRef: ViewContainerRef,
        private readonly modalService: ModalService
    ) {
    	window.onresize = () => {
            this.scrollBarHorizontal = (window.innerWidth < 960);
            this.columnModeSetting = (window.innerWidth < 960) ? 'standard':'force';
        };
        this.paginate.pageNumber = 0;
        this.paginate.limit = 10;
        this.paginate.sortField = 'last_name';
        this.paginate.sortOrder = 'ASC';

        this.paginateOffice.pageNumber = 0;
        this.paginateOffice.limit = 10;

        this.paginateHolding.pageNumber = 0;
        this.paginateHolding.limit = 10;
        this.paginateHolding.sortField = 'percentage';
        this.paginateHolding.sortOrder = 'desc';

        this.paginateLicensed.pageNumber = 0;
        this.paginateLicensed.limit = 10;

        this.paginateFirmDba.pageNumber = 0;
        this.paginateFirmDba.limit = 10;

        this.paginateFirmOwners.pageNumber = 0;
        this.paginateFirmOwners.limit = 3;
        this.paginateFirmOwners.sortField = 'last_name';
        this.paginateFirmOwners.sortOrder = 'ASC';

        this.paginateFirmContact.pageNumber = 0;
        this.paginateFirmContact.limit = 9;

        // this.profile_url = this.router.url.split('/');
        this.tooltipColor = '#000';
        
        this.advizorProService.$advTooltip.subscribe(value => {
            if (value) {
                this.toolTipOrnament = value as unknown as typeof ToolTipMessage
                this.toolTipIconOrnament = getIconsDataset(value as Record<string, string>);
            }
        });
    }
    
    ngOnInit() {
        this.previousWidth = window.innerWidth;
        this.scrollBarHorizontal = (this.previousWidth < 960);
        this.columnModeSetting = (this.previousWidth < 960) ? 'standard' : 'force';

        if (this.profileData) {

            this.user = this.auth.getUser();
        
            //if Team Access
            if (this.user.has_access_team) {
                this.onlyTeamAccess = true;
            }
        
            if (this.user.salesforce) {
                this.salesforceAccess = this.user.salesforce
            }
        
            if (this.user.hubspot) {
                this.hubspotAccess = this.user.hubspot;
            }

            this.profileType = this.profileData.type;
            this.crd = this.profileData.crd;

            this.iaProfile = "https://adviserinfo.sec.gov/firm/summary/" + this.crd;
            this.brokerProfile = "https://brokercheck.finra.org/firm/summary/" + this.crd;
            this.getFirmProfile(this.profileType);

            this.advisorFollowStatus(this.crd, 'firm');

        }
    }

    ngAfterContentChecked() {
        const newWidth = window.innerWidth;
        if (this.previousWidth !== newWidth) {
            this.previousWidth = newWidth;
            this.scrollBarHorizontal = (newWidth < 960);
            this.columnModeSetting = (newWidth < 960) ? 'standard' : 'force';

            // Debounce the resize event dispatch
            if (!this.resizeTimeout) {
                this.resizeTimeout = setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    this.cdr.detectChanges();
                    this.resizeTimeout = null; // Clear timeout after use
                }, 100);  // Adjust timeout value if needed
            }
        }
    }

ngAfterViewInit() {
    if (this.profileType != 'family') {
        fromEvent(this.contactSearch.nativeElement, 'keyup')
            .pipe(
                map((k: any) => k.target.value),
                debounceTime(500),
        ).subscribe(val => {
            this.contact_search_keyword = val;
            this.paginate.pageNumber = 0;
            this.paginate.limit = 10;
            this.getFirmContact(this.profileType, this.contact_search_keyword);
        });
    }
    this.subject.pipe(
        debounceTime(500)
    ).subscribe(() => {
        this.paginateFirmDba.pageNumber = 1;
        this.paginateFirmDba.limit = 10;
        this.getFirmDba();
    });

    this.subjectHoldingSearch.pipe(
        debounceTime(500)
    ).subscribe(() => {
        this.paginateHolding.pageNumber = 0;
        this.paginateHolding.limit = 10;
        this.getFirmHolding();
    });

    this.subjectFirmOffice.pipe(
        debounceTime(500)
    ).subscribe(searchTextValue => {
        this.paginateOffice.pageNumber = 0;
        this.paginateOffice.limit = 10;
        this.getFirmOffice(searchTextValue);
    });
}

    onKeyUp(searchTextValue: any){
        this.team_search_keyword = searchTextValue.target.value;
        this.subject.next(this.team_search_keyword);
    }

    onHoldingKeyUp(searchTextValue: any) {
        this.holding_search_keyword = searchTextValue.target.value;
        this.subjectHoldingSearch.next(this.holding_search_keyword);
    }

    firmOfficeKeyUp(searchTextValue: any) {
        // this.holding_search_keyword = searchTextValue.target.value;
        this.subjectFirmOffice.next(searchTextValue.target.value);
    }

	getFirmProfile(type){
        if(type == 'advisor'){
            this.method = this.advizorProService.getFirmProfile(this.crd);
        }else if(type == 'family'){
            this.method = this.advizorProService.getFamilyFirmProfile(this.crd);
        }else if(type == 'bank'){
            this.method = this.advizorProService.getBankAndTrustFirmProfile(this.crd);
        }else if(type === DEFINED_BENEFIT || type === DEFINED_CONTRIBUTION){
            // Use this instead of increase the complexity
            this.method = this.advizorProService.getFirmProfileByType(type, this.crd)
            
        }


        return this.method.subscribe(
            (data: any) => {
                if (data) {

                    this.holding_date = data.data.latest_13f_as_of_date;
                    this.getFirmHolding();
                    this.firmProfile = data.data;

                    let finalArray = data.data.firm_tags.reduce((result, { tag_category, ...rest }) => {

                        let group = result.find(g => g.tag_category === tag_category);

                        if (!group) {
                            group = { tag_category, firm_tags: [] };
                            result.push(group);
                        }

                        if (tag_category == 'Platform') {
                            this.platforms.push({...rest});
                        }

                        group.firm_tags.push({ tag_category, ...rest });

                        return result;
                    }, []);

                    //remove Custodian and Platform from tags
                    finalArray = finalArray.filter(item => item.tag_category !== 'Custodian' && item.tag_category !== 'Platform');

                    this.firmProfile.firm_tags = finalArray;

                    this.firm_id = data.data.id;

                    this.checkTrafficIqAccess();
                    this.getFirmInsight();
                    this.getFirmContact(this.profileType);

                    if (this.profileType != 'family') {
                            this.paginateFirmDba.pageNumber = 1;
                            this.paginateFirmDba.limit = 10;

                        this.getFirmOwners();
                        this.getFirmOffice();
                        this.getFirmDba();
                        this.checkAdvpdfType();
                    }



                    data.data.firm_attribute.forEach(item => {
                        if (item.attribute.slug == 'main_url') {
                            this[item.attribute.slug].push(item);
                        } else {
                            this[item.attribute.slug] = item;
                        }

                        if (this.attributes.hasOwnProperty(item.attribute.slug)) {
                            this.attributes[item.attribute.slug].push(item);
                        } else {
                            var array = [];
                            array.push(item);
                            this.attributes[item.attribute.slug] = array;
                        }
                    });

                    if (data.data.has_13f_filing) {

                        let currentYear = Math.trunc(this.holding_date / 100);
                        let currentMonth = this.holding_date % 100;

                        let currentDateLabel = 'Q1' + ' ' + currentYear;
                        if (currentMonth == 6) {
                            currentDateLabel = 'Q2' + ' ' + currentYear;
                        } else if (currentMonth == 9) {
                            currentDateLabel = 'Q3' + ' ' + currentYear;
                        } else if (currentMonth == 12) {
                            currentDateLabel = 'Q4' + ' ' + currentYear;
                        }

                        this.holdingDates.push({ label: currentDateLabel, value: this.holding_date });

                        data.data.holdings.forEach(ele => {
                            let currentYear = Math.trunc(ele / 100);
                            let currentMonth = ele % 100;

                            let label = 'Q1'+' '+currentYear;
                            if (currentMonth == 6) {
                                label = 'Q2'+' '+currentYear;
                            } else if (currentMonth == 9) {
                                label = 'Q3'+' '+currentYear;
                            } else if (currentMonth == 12) {
                                label = 'Q4'+' '+currentYear;
                            }

                            if (currentDateLabel != label) {
                                this.holdingDates.push({ label: label, value: ele });
                            }
                        });
                    } else {
                        this.holdingListStatus = false;
                    }

              
                     if (data.data.historical_aum) {
                         this.getAumChart(data.data.historical_aum);
                     }

                    let logo_name = this.firmProfile.firm_name.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}0-9\s]/g, '');

                    this.firmProfile.logo_name = logo_name.charAt(0);

                    if (data.data.total_sma > 0) {
                        this.getsmaPieChart(data.data.sma,this.firmProfile?.total_sma);
                        if (this.firmProfile.sma) {
                            // Consistent sort order for SMA Table
                            const smaOrder = [
                                'Equity - Exchange',
                                'Equity - OTC',
                                'U.S. Government/Agency',
                                'Municipal Bonds',
                                'Sovereign Bonds',
                                'Corporate - Investment Grade',
                                'Corporate - Non-Investment Grade',
                                'Derivatives',
                                'Public Funds',
                                'Private Funds',
                                'Cash',
                                'Other'
                            ];
                            
                            this.firmProfile.sma.sort((a, b) => {
                                const aIndex = smaOrder.indexOf(a.asset_type);
                                const bIndex = smaOrder.indexOf(b.asset_type);
                                return aIndex - bIndex;
                            });
                        }
                    }
                }
            },
            (_err: unknown) => {
            console.error("opening firm profile.", _err)
		    // skipcq: JS-0328
		    this.router.navigate(["not-found"])
        });
    }

    getAumChart(data) {
        this.aumTotalChart = {
            legend: {
                data: ['Total', 'Discretionary', 'Non-Discretionary']
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data.date,
                axisLabel: {
                    rotate: 60 //If the label names are too long you can manage this by rotating the label.
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (a) { return (a / 1000000).toLocaleString() + 'M' }, //If the don't need number format then remove this line
                    align: 'center'
                },
            },
            series: [
                {
                    name: 'Total',
                    data: data.total_aum,
                    type: 'bar',
                    smooth: true
                },
                {
                    name: 'Discretionary',
                    data: data.discretionary_aum,
                    type: 'bar',
                    smooth: true
                },
                {
                    name: 'Non-Discretionary',
                    data: data.non_discretionary_aum,
                    type: 'bar',
                    smooth: true
                }
            ]
        };

        this.aumAccountChart = {
            legend: {
                data: ['Total', 'Discretionary', 'Non-Discretionary']
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: data.date,
                axisLabel: {
                    rotate: 60 //If the label names are too long you can manage this by rotating the label.
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (a) { return (a / 1000) + 'K' }, //If the don't need number format then remove this line
                    align: 'center'
                },
            },
            series: [
                {
                    name: 'Total',
                    data: data.total_accounts,
                    type: 'bar',
                    smooth: true
                },
                {
                    name: 'Discretionary',
                    data: data.discretionary_accounts,
                    type: 'bar',
                    smooth: true
                },
                {
                    name: 'Non-Discretionary',
                    data: data.non_discretionary_accounts,
                    type: 'bar',
                    smooth: true
                }
            ]
        };
    }

    pageData($event: any) {
        this.paginate.pageNumber = $event.offset;
        this.getFirmContact(this.profileType);
    }

    onContactSort($event: any) {
        this.paginate.sortField = $event.sorts[0].prop;
        this.paginate.sortOrder = $event.sorts[0].dir;
        this.paginate.pageNumber = 0;
        this.getFirmContact(this.profileType);
    }

    sortOfficeData($event: any) {
        this.paginateOffice.pageNumber = $event.page - 1;
        this.getFirmOffice();
    }

    sortHoldingData($event: any) {
        this.paginateHolding.pageNumber = $event.page - 1;
        this.getFirmHolding();
    }

    onHoldingSort($event) {
        this.paginateHolding.sortField = $event.sorts[0].prop;
        this.paginateHolding.sortOrder = $event.sorts[0].dir;
        this.paginateHolding.pageNumber = 0;
        this.getFirmHolding();
    }

    sortFirmOwnersData($event: any) {
        this.paginateFirmOwners.pageNumber = $event - 1;
        this.getFirmOwners();
    }

    limitsortFirmOwnersData($event: any) {
        let limit = parseInt($event.target.value)
        this.paginateFirmOwners.limit = limit;
        this.getFirmOwners();
    }

    sortFirmContactPagination(event) {
		this.paginate.pageNumber = parseInt(event);
        this.getFirmContact(this.profileType);
	}

	getFirmContact(type, search=null){
        this.paginate.limit = this.paginateFirmContact.limit ? this.paginateFirmContact.limit : 9;

        let searchContact = {
            limit: this.paginate.limit,
            page: this.paginate.pageNumber,
            sortField: this.paginate.sortField,
            sortOrder: this.paginate.sortOrder,
            crd: (type == 'advisor') ? this.crd : null,
            fo_id: (type == 'family') ? this.crd : null,
            bt_id: (type == 'bank') ? this.crd : null,
            search: search,
            advizor_firm_id: this.firm_id,
            searchAfter: this.contactSearchAfter,
            score_id: this.profileData.score_id
        };
        return this.advizorProService.firmContacts(searchContact).subscribe(
            (data: any) => {
                if (data) {
                    this.contacts = data.data;
                    this.contactSearchAfter = data.data.searchAfter;
                    this.totalContactPages = Math.ceil(data.data.total / this.paginate.limit);
                }
            },
        );
    }

    nextPage() {
        if (this.currentContactPage < this.totalContactPages) {
            this.currentContactPage++;
            this.paginate.sortOrder = 'ASC';
            this.getFirmContact(this.profileType, this.contact_search_keyword);
        }
    }

    previousPage() {
        if (this.currentContactPage > 1) {
            this.currentContactPage--;
            this.paginate.sortOrder = 'DESC';
            this.getFirmContact(this.profileType, this.contact_search_keyword);
        }
    }

    getFirmOwners(search=null){
        let searchContact = {
            limit: this.paginateFirmOwners.limit,
            page: this.paginateFirmOwners.pageNumber + 1,
            sortField: this.paginateFirmOwners.sortField,
            sortOrder: this.paginateFirmOwners.sortOrder,
            advizor_firm_id: this.firm_id,
            search: search
        };
        return this.advizorProService.firmOwners(searchContact).subscribe(
            (data: any) => {
                if (data) {
                    this.ownerContacts = data.data;
                }
            },
        );
    }

    getFirmDiff(){
        this.advizorProService.firmDiff(this.crd).subscribe(
            (data: any) => {
                if (data) {
                    this.firm_diff = data.data;
                }
            },
        );
    }

    getFirmOffice(search = null) {
        let searchOffice = {
            limit: this.paginateOffice.limit,
            page: this.paginateOffice.pageNumber + 1,
            advizor_firm_id: this.firm_id,
            search: search
        };
        return this.advizorProService.firmOffice(searchOffice).subscribe(
            (data: any) => {
                if (data) {
                    this.offices = data.data;

                    // this.setMenuObj('firm', '#office_locations', {id:"#office_locations", label:'Office Locations', link:'/'+this.profileType+'/firm/'+this.crd, rank:8 });
                }
            },
        );
    }

    getFirmInsight() {
        this.advizorProService.firmtInsight(this.firm_id).subscribe(
            (data: any) => {
                if (data) {
                    this.insights = data.data;
                    this.insights_limit = data.data.slice(0,4);
                }
            },
        );
    }

    getFirmDba() {
        const args = {
            limit: this.paginateFirmDba.limit,
            page: this.paginateFirmDba.pageNumber,
            advizor_firm_id: this.firm_id,
            search: this.team_search_keyword
        };
        return this.advizorProService.firmDba(args).subscribe(
            (res: DbaResponse) => {
                if (res?.status === '200' && res?.data) {
                        this.advizorFirmDba = res.data;
                        this.totalDbaPages = Math.ceil(res?.data?.total / this.paginateFirmDba.limit)
                        this.currentDbaPage = res?.data?.current_page

                        if (this.advizorFirmDba.total) {
                                this.teamHide = true;
                        }
                }
            },
        );
    }

    getFirmHolding() {
        let searchHolding = {
            limit: this.paginateHolding.limit,
            page: this.paginateHolding.pageNumber + 1,
            sortField: this.paginateHolding.sortField,
            sortOrder: this.paginateHolding.sortOrder,
            crd: this.crd,
            search: this.holding_search_keyword,
            holding_date: this.holding_date,
            is_export: 0
        };
        return this.advizorProService.firmHolding(searchHolding).subscribe(
            (data: any) => {
                if (data) {
                    this.holdings = data.data;
                    this.filling = data.filling;
                }
            },
        );
    }

    attribute(attribute, type=null) {
        if (!this.attributes[attribute]) {
            return null;
        }

        if (type == 'single') {
            return this.attributes[attribute][0]['value'];
        } else if (type == 'comma') {
            let returnval = [];
            this.attributes[attribute].forEach(item => {
                if (returnval.indexOf(item.value) == -1) {
                    returnval.push(item.value);
                }
            });
            return returnval.join(', ');
        } else if (type == 'commaToArray') {
            return this.attributes[attribute][0]['value'].split(',');
        } else {
            let returnval = [];
            this.attributes[attribute].forEach(item => {
                if (returnval.indexOf(item.value) == -1) {
                    returnval.push(item.value);
                }
            });
            return returnval;
        }
    }

    logAttribute(attribute:any) {
        this.advizorProService.activityRecord({'data':attribute, 'activity':'link-open'}, attribute.id).subscribe((response: any) => {
            window.open(response.response.value, "_blank");
        });
    }

    openPortLink(url) {
        if (url) {
            window.open(url, "_blank");
        }
    }

    mainUrlLog(attribute) {
        this.firm_web = true;
        this.firmAttributes = attribute;
    }

    getAttribute(attributeId: string) {
        let attribute = this.attributes[attributeId];
        if (attribute?.[0] && attribute[0]['value']) {
            return attribute[0]['value'];
        }
        return null;
    }

    displayAddress(add, type, isSignleLine=false){
        if(add)
        {
            let address:any;
            address = (add.address_1) ? add.address_1 + ' ' : '' ;
            address += (add.address_2) ? add.address_2 + ' ' : '' ;
            if (isSignleLine) {
                address += (add.city) ? add.city + ' ' : '' ;
            } else {
                address += (add.city) ? '<br/>' + add.city + ' ' : '' ;
            }
            address += (add.state) ? add.state + ' ' : '' ;
            address += (add.zip) ? add.zip + ' ' : '' ;
            address += (add.country) ? add.country  : '' ;
            if(type == 'address'){
                return address;
            }else{
                return add.phone;
            }
        }
    }

    formatStringNumber(value) {
        return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    exportModel() {
        this.userService.userReportCount().subscribe((data:any) => {
            if (!this.user.ap_user_limit_csv || (this.user.ap_user_limit_csv && this.user.ap_user_limit_csv <= data.advisor_excel_count)) {
              this.limit_over_model = true;
              return false;
            }

            this.downloadMessage = { status_message: 'Your report is processing...', status: 1 };
            this.percent_complete = 0;

            this.exportStatus = true;
            this.isDownload = false;
            this.advizorProService.contactExport({
                crd: this.crd,
                search: '',
            }).subscribe((res:any) => {
                if(res.code == 400 && res.errors == 'Your download limit is exceed.'){
                    this.exportStatus = false;
                    this.limit_over_model = true;
                    return false;
                }
                if (res.status == '200') {
                    // this.isAdvisorContactData = true;

                    this.contactExport = interval(1500)
                        .subscribe(() => {
                            this.checkContactReport(res.reportId);
                        });
                }
            });
        })
    }
    
    exportTeamModel() {
        this.exportTeamStatus = true;
        this.isTeamDownload = false;
        this.advizorProService.firmDba({
            advizor_firm_id: this.firm_id,
            search: this.team_search_keyword,
            limit: 30,
            page: 1,
            is_export:1,
        }).subscribe((res:any) => {
            if (res.data?.link) {
                this.isTeamDownload = true;
                window.open(res.data.link);
            }
            this.exportTeamStatus = false;
        });
    }

    exportOfficeModel() {

        this.userService.userReportCount().subscribe((data:any) => {
            if (!this.user.ap_user_limit_csv || (this.user.ap_user_limit_csv && this.user.ap_user_limit_csv <= data.advisor_excel_count)) {
              this.limit_over_model = true;
              return false;
            }

            this.downloadMessage = { status_message: 'Your report is processing...', status: 1 };
            this.percent_complete = 0;
            this.exportOfficeStatus = true;

            this.advizorProService.officeExport({
                crd: this.crd,
            }).subscribe((res:any) => {
                if (res.data?.link) {
                    this.excel_office_link = res.data.link;
                    this.exportOfficeStatus = false;
                    window.open(res.data.link);
                }
            });
        });
    }

    checkContactReport(id) {
        this.reportService.getReportStatus(id).subscribe((data: any) => {
            this.percent_complete = data.percent_complete;
            if (data.status == 'complete') {
                this.exportStatus = false;
                this.contactExport.unsubscribe();
                window.open(data.link);
            } else if (data.status == 'error') {
                // this.exportStatus = false;
                this.downloadMessage.status_message = data.status_message;
                this.downloadMessage.status = 0;
                this.contactExport.unsubscribe();
                // this._helper.errorMessage('', data.status_message);
            }
        });
    }

    downloadReport() {
        setTimeout(() => {
            this.isDownload = false;
        }, 200);
    }

    exportHoldModel() {
        this.exportHoldStatus = true;
        this.isHoldDownload = false;
        this.advizorProService.firmHolding({
            crd: this.crd,
            limit: 30,
            page: 1,
            is_export:1,
            search: this.holding_search_keyword,
            holding_date: this.holding_date,
        }).subscribe((res:any) => {
            if (res.data?.link) {
                this.isHoldDownload = true;
                this.excel_hold_link = res.data.link;
                window.open(res.data.link);
            }
            this.exportHoldStatus = false;
        });
    }

    changeHolding () {
        this.getFirmHolding();
    }

    downloadHoldingReport() {
        this.isHoldDownload = false;
    }

    advBadge() {
        this.brochures = [];
        this.adv_model = true;
        this.getBrochures();
    }

    getBrochures() {
        this.advizorProService.getBrochures(this.crd).subscribe((res: any) => {
            if (res.status == '200') {
                this.brochures = res.data;
            }
        });
    }

    closeModal(type) {
        switch(type) {
            case 1:
                this.adv_model = false;
                break;
			case 2:
				this.limit_over_model = false;
				break;
			case 3:
				this.waitingBox = false;
				break;
			case 4:
				this.firm_web = false;
				break;
            default:
        }
    }

    openDbaContact(dba: Dba) {
        this.dbaData = dba;
        this.modalService.openModal(this.dbaContactId)
    }

    followAdvizor() {
        if (this.user.account_number && !this.advisorFollow) {
          this.advisor_follow_type_model = true;
        } else {
          this.followAdvizorUpdate();
        }
    }

    followAdvizorUpdate() {
        this.advisorFollowData.crd = this.crd;
        this.advisorFollowData.type = 'firm';
        this.advizorProService.followAdvizor(this.advisorFollowData).subscribe((response: any) => {
            if (response.response == 'unfollow') {
                this.advisor_follow_status = null;
                this.advisor_user_follow_type = 'private';
                this.advisorFollow = true;
                this.advisorFollowTitle = 'Unfollow ' + this.titleCaseWord(this.advisorFollowData.type);
            } else {
                this.advisorStatus = '';
                this.advisorFollow = false;
                this.advisorFollowTitle = 'Follow ' + this.titleCaseWord(this.advisorFollowData.type);
            }
        });
    }

    titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

    advisorFollowStatus(crd, type) {
        this.advizorProService.followStatus(crd, type).subscribe((response: any) => {
            if (response.response.follow_status == 'unfollow') {
                this.advisorFollowTitle = 'Unfollow ' + this.titleCaseWord(type);
                this.advisorFollow = true;
                if(response.response.status){
                this.advisorStatus = response.response.status;
                }
            } else {
                this.advisorFollow = false;
                this.advisorFollowTitle = 'Follow ' + this.titleCaseWord(type);
            }
        });
    }

    advFollowTypeContentCancel() {
        this.advisor_follow_type_model = false;
    }

    onAdvisorFollowType() {
        this.advisor_follow_type_model = false;
        this.advisorFollowData.follow_type = this.advisor_user_follow_type;
        this.advisorFollowData.status = this.advisor_follow_status;
        this.advisorStatus = this.advisor_follow_status;
        this.followAdvizorUpdate();
    }

    advFirmTag() {
        this.advisor_firm_tag_model = true;
    }

    advFirmTagCancel() {
        this.advisor_firm_tag_model = false;
    }

    calculateDateDifference(date: string | Date): string {

        const establishDate = typeof date === 'string' ? new Date(date) : date;

        if (!(establishDate instanceof Date) || isNaN(establishDate.getTime())) {
          return '';
        }

        const diffInMonths = this.monthDiff(establishDate, this.currentDate);
        const years = Math.floor(diffInMonths / 12);

        if (years === 0) {
            return 'Recently Established';
        }
        return `${years} years`;
      }

      private monthDiff(d1: Date, d2: Date): number {
        return d2.getMonth() - d1.getMonth() + 12 * (d2.getFullYear() - d1.getFullYear());
      }

    advTechStack() {
        this.tech_stack_modal = true;
    }

    advTechStackCancel() {
        this.tech_stack_modal = false;
    }

    checkAdvpdfType() {
        if (this.profileType == 'insurance' && this.profileData.sub_type == 'firm') {
            this.advizorPdf = 'in-firm';
        }  else {
            this.advizorPdf = this.profileData.sub_type;
        }
    }

    importContact(type, adv_type) {
        const initialState = {
          list: {
            "firm_total": 0,
            "person_total": 0,
            "type": type,
            "adv_type": adv_type,
            "npn": '',
            "crd": '',
            "firm_crd": this.profileType != 'family' ? this.crd : '',
            "search": '',
            "firm_fo_id": this.profileType == 'family' ? this.crd : '',
            "person_fo_id": '',
          }
        };

        const modal = this.modal.create<SyncModalComponent>({
          nzContent: SyncModalComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzComponentParams: initialState,
          nzOnOk: () => new Promise(resolve => {
            setTimeout(resolve, 200);
          })
        });

        modal.componentInstance.changeStatus.subscribe((res) => {
          if (res.type == 'salesforce') {
            this.salesforceImportData = true;
          } else {
            this.hubspotImportData = true;
          }
        })
    }

    getInsights() {
       this.insights_limit = this.insights;
    }

    getsmaPieChart(data, total_sma) {
        data = data.map(item => ({ percent: item.percent, asset_type: item.asset_type }));
        const legendData = data.map(item => item.asset_type);
        const set_color = ['#5B61D6', '#EA334B', '#FCA997', '#AE59DC', '#7FCEBA', '#3070F5', '#F5C242', '#C3E1FF', '#B91293', '#60C04E', '#AA7547', '#2E8235'];
        
        // Pie chart configuration
        this.smaPieChart = {
            color: set_color,
            tooltip: {
                trigger: 'item',
                formatter: '<b>{b}:</b> {c}%'
            },
            series: [{
                name: 'Asset Type',
                type: 'pie',
                radius: ['38%', '52%'],
                center: ['50%', '50%'], // Center in container
                avoidLabelOverlap: true,
                label: {
                    show: true,
                },
                labelLine: {
                    show: true
                },
                startAngle: 90,
                data: data.filter(item => item.percent > 0).map(item => ({ 
                    value: item.percent, 
                    name: item.asset_type 
                }))
            }]
        };

        // Legend configuration as separate chart
        this.smaPieLegendChart = {
            color: set_color,
            tooltip: {
                show: false
            },
            legend: {
                orient: 'vertical',
                icon: 'circle',
                left: 0, // Start from left edge
                top: 'middle',
                itemGap: 12,
                formatter: function (name) {
                    const dataIndex = legendData.findIndex(item => item === name);
                    const percentage = data[dataIndex].percent;
                    return `${name}: ${percentage}%`;
                },
            },
            series: [Object.assign({}, this.smaPieChart.series[0], { label: { show: false }, radius: [0, 0] })]
        };
    }

    addTextIfValueExists(items: {value: string, text: string}[]){
        let display = '';
        let firstItem = false;
        for(const item of items){

            if (item.value == undefined) continue
            
            if (firstItem) display += ' | '
            display += item.text
            firstItem = true
        }
        
        return display
    }

    formatMoney(amount?: number) {
        if (amount === undefined || amount === null) return "--";
        return "$" + Intl.NumberFormat().format(amount/1000000) + 'M';
    }

    checkYear(year) {
        let trimmedYear = year.replace(/^0+/, '');

        if (trimmedYear === '') {
            return '-';
        } else {
            return trimmedYear;
        }
    }

    trackByFn(index: number, item: any): string {
            return `${item?.id}${index}`
    }

    nextDbaPage(): void {
            if (this.currentDbaPage < this.totalDbaPages) {
                    this.paginateFirmDba.pageNumber++;
                    this.getFirmDba();
            }
    }

    previousDbaPage(): void {
            if (this.currentDbaPage > 1) {
                    this.paginateFirmDba.pageNumber--;
                    this.getFirmDba();
            }
    }

    personNameFilter(row) {
        if (row) {
            return row.first_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0)+row.last_name.replaceAll(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').charAt(0);
        }
    }

    getContactLink(row){

        if(row?.person_crd)
            return ['/advisor/person', row?.person_crd]
        if(row?.fo_id)
            return ['/family/person', row?.fo_id]
        if(row?.bt_id)
            return ['/bank/person', row?.bt_id]
        if(row?.db)
            return [`/${DEFINED_BENEFIT}/person/${row?.id}`]
        if(row?.dc)
            return [`/${DEFINED_CONTRIBUTION}/person/${row?.id}`]
    }

    getSortedFlags = (flags: string[]) => flags.sort((a, b) => (b.includes('fa fa-flag') ? 1 : 0) - (a.includes('fa fa-flag') ? 1 : 0));

    getIcons(row: any): Icon[] {
		return getIconsByRowKeys(row);
	}

    checkTrafficIqAccess() {
        if (!this.user.has_access_traffic_iq) {
            return;
        }

        this.advizorProService.getTrafficIQSummary(this.firm_id).subscribe((res: any) => {
            this.trafficIqAccess = res && res.total_sessions > 0;
        });
    }
}
