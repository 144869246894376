import {
  Component,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime } from "rxjs/operators";

import { AdvizorProService } from "../../../services/advizor-pro.service";

interface Summary {
  latest_visit: string | null;
  total_sessions: number;
  total_visits: number;
}

interface History {
  url: string | null;
  date: string | null;
  keyword: string | null;
  source: string;
  city: string;
  state: string;
  country: number;
}

@Component({
  selector: "tab-traffic-iq",
  templateUrl: "./tab-traffic-iq.component.html",
  styleUrls: ["./tab-traffic-iq.component.scss"],
})
export class TabTrafficIQComponent implements AfterViewInit, OnChanges {
  @Input() firmId: number | null = null;

  private subjectTrafficIq: Subject<string> = new Subject();
  private pendingRequests = 2;

  public loading = true;
  public columnModeSetting = window.innerWidth < 960 ? "standard" : "force";
  public scrollBarHorizontal = window.innerWidth < 960;
  public paginateHistory: { page: number; size: number } = {
    page: 0,
    size: 10,
  };
  public summary: Summary | null = null;
  public history: { data: History[]; total: number } = { data: [], total: 0 };

  constructor(private readonly advizorProService: AdvizorProService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.firmId) {
      if (!this.firmId) {
        return;
      }

      this.loading = true;
      this.pendingRequests = 2;
      this.getTrafficIqSummary();
      this.getTrafficIqHistory();
    }
  }

  ngAfterViewInit(): void {
    this.subjectTrafficIq
      .pipe(debounceTime(500))
      .subscribe((searchTextValue) => {
        this.paginateHistory.page = 0;
        this.paginateHistory.size = 10;
        this.getTrafficIqHistory(searchTextValue);
      });
  }

  private getTrafficIqSummary() {
    if (!this.firmId) return;

    this.advizorProService.getTrafficIQSummary(this.firmId).subscribe({
      next: (response: Summary) => {
        if (!response) return;
        this.summary = response;
      },
      error: () => {},
      complete: () => {
        this.checkLoadingDone();
      },
    });
  }

  private getTrafficIqHistory(search: string | null = null) {
    if (!this.firmId) return;

    let args = {
      size: this.paginateHistory.size,
      page: this.paginateHistory.page + 1,
      search: search ?? "",
    };

    this.advizorProService.getTrafficIQHistory(this.firmId, args).subscribe({
      next: (response: { data: History[]; total: number }) => {
        if (!response) return;

        this.history.data = response.data ?? [];

        if ("total" in response) {
          this.history.total = response.total;
        }
      },
      error: () => {},
      complete: () => {
        this.checkLoadingDone();
      },
    });
  }

  private checkLoadingDone() {
    this.pendingRequests--;
    if (this.pendingRequests === 0) {
      this.loading = false;
    }
  }

  public sortDataHistory($event: { page: number }) {
    this.paginateHistory.page = $event.page - 1;
    this.getTrafficIqHistory();
  }

  public trafficIqKeyUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    this.subjectTrafficIq.next(value);
  }
}
