<div
  *ngIf="loading; else elseBlock"
  class="col align-self-center d-flex justify-content-center pt-4 pb-4"
>
  <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
</div>
<ng-template #elseBlock>
  <div class="row scrollsection mb-4 px-4 py-3">
    <div class="col-md-4">
      <div class="card card-rounded">
        <div
          class="p-2 card-body d-flex flex-column justify-content-center align-items-center m-0"
        >
          <h4 class="m-0 iq-title">
            {{
              summary?.latest_visit
                ? (summary.latest_visit | date : "longDate")
                : "--"
            }}
          </h4>
          <h6 class="fs-12">LATEST VISIT</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-rounded">
        <div
          class="p-2 m-0 card-body d-flex flex-column justify-content-center align-items-center m-0"
        >
          <h4 class="m-0 iq-title">{{ summary?.total_visits ?? "0" }}</h4>
          <h6 class="fs-12">PAGE VIEWS</h6>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-rounded">
        <div
          class="p-2 card-body d-flex flex-column justify-content-center align-items-center m-0"
        >
          <h4 class="m-0 iq-title">{{ summary?.total_sessions ?? "0" }}</h4>
          <h6 class="fs-12">SESSIONS</h6>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div
        class="row m-0 justify-content-end align-items-center c-section px-3"
      >
        <div class="row seach-export-section">
          <div class="col-lg-12 p-0">
            <input
              type="text"
              name="office_search"
              (keyup)="trafficIqKeyUp($event)"
              class="form-control search-location"
              placeholder="Search by URL, Keyword, Source or Location"
            />
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <ngx-datatable
          class="table table-hover table-v2"
          [rows]="history?.data"
          [headerHeight]="43"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [columnMode]="columnModeSetting"
          [scrollbarH]="scrollBarHorizontal"
          [externalPaging]="true"
          [count]="history?.total"
          [offset]="paginateHistory.page"
          [limit]="paginateHistory.size"
          (page)="sortDataHistory($event)"
        >
          <ngx-datatable-column [sortable]="false" name="URL" [width]="400">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="text-truncate" [title]="row.url">{{ row.url }}</div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [sortable]="false" name="Campaign">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="text-truncate" [title]="row.keyword">
                {{ row.keyword }}{{ row.keyword ? ", " : "" }}{{ row.source }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [sortable]="false" name="City">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.city }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [sortable]="false" name="State">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.state }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [sortable]="false"
            name="Page Viewed"
            [width]="210"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.date | date : "MM/dd/yy hh:mm a" }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="pagination-v2">
                <div class="page-count p-0">
                  <span class="page-total">
                    {{ rowCount?.toLocaleString() ?? "0" }} total entries
                  </span>
                </div>
                <datatable-pager
                  class="custom-datatable-pager"
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="sortDataHistory($event)"
                >
                </datatable-pager>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</ng-template>
