import {
    Component,
    OnInit,
    ViewEncapsulation,
    HostListener,
} from "@angular/core";
import { RootLayout } from "../root/root.component";
import * as $ from "jquery";
import 'quill-mention';
import Quill from 'quill';
import { ImageHandler, Options } from 'ngx-quill-upload';
import { EmailAutomationService } from "../../../shared/services/email-automation.service";
import posthog from 'posthog-js';
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "../../../../environments/environment";

Quill.register('modules/imageHandler', ImageHandler);

let posthogInitialized = false;
function initPostHog(user, router: Router) {
    try {   
        if (posthogInitialized || !environment.production) {
            return;
        }

        posthogInitialized = true;

        posthog.init("phc_K1VWfbMbHwz4sgUasYP0ZASTqr9VrzuUgCSGRk2haG7", {
            api_host: "https://us.i.posthog.com",
            person_profiles: "identified_only",
            loaded: (posthog) => {
                posthog.identify(user.id, {
                    email: user.email,
                    name: user.name,
                    company: user.company,
                    account_number: user.account_number,
                });
            }
        });

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                posthog.capture('$pageview');
            }
        });

    } catch (error) {
        console.error('Error initializing PostHog', error);
    }
}


@Component({
    selector: "corporate-layout",
    templateUrl: "./corporate.component.html",
    styleUrls: ["./corporate.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CorporateLayout extends RootLayout implements OnInit {

    menuLinks = [
        {
            label: "Dashboard",
            routerLink: "/dashboard",
            iconType: "pg",
            iconName: "addashboard",
            thumbNailClass: "text-white",
        },
        {
            label: "PlanPro Search",
            routerLink: "/search",
            iconType: "pg",
            iconName: "ad_search_status",
            thumbNailClass: "text-white",
        },
        {
            label: "PlanScanner (Beta)",
            routerLink: "/upload",
            iconType: "fa",
            iconName: "cloud-download-alt",
            thumbNailClass: "text-white",
        },
        {
            label: "Search",
            routerLink: "/advisor/search",
            iconType: "pg",
            iconName: "ad_search_status",
            thumbNailClass: "text-white",
        },
        {
            label: "Exports",
            routerLink: "/report",
            iconType: "pg",
            iconName: "ad_document_download",
            thumbNailClass: "text-white",
        },
        {
            label: "My Data",
            iconType: "pg",
            iconName: "ad_folder_open",
            thumbNailClass: "text-white",
            toggle: "close",
            submenu: [
                {
                    label: "My Plans",
                    routerLink: "/my-data/followed-plan",
                    iconType: "pg",
                    iconName: "ad_folder_open",
                    thumbNailClass: "text-white",
                },
                {
                    label: "My Advisors",
                    routerLink: "/my-data/followed-advisor",
                    iconType: "pg",
                    iconName: "ad_folder_open",
                    thumbNailClass: "text-white",
                },
                {
                    label: "Tasks",
                    routerLink: "/my-data/task",
                    iconType: "pg",
                    iconName: "ad_folder_open",
                    thumbNailClass: "text-white",
                }
            ],
        }
    ];
    user: any;
    _showToolTip = false;
    current_year = new Date().getFullYear();
    modules: any;
    mentionFields: any = [];

    public emailAutomationService: EmailAutomationService;

    ngOnInit(): void {
        this.changeLayout("menu-pin");
        this.changeLayout("menu-behind");
        //Will sidebar close on screens below 1024
        this.autoHideMenuPin();
        this.setTextEditor();

        this.user = this.auth.getUser();

        // Initialize PostHog
        initPostHog(this.user, this.router);



        if (!this.user.plan_scanner) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label != "PlanScanner (Beta)") {
                    return item;
                }
            });
        }

        if (
            this.user.user_access.find(
                (x) =>
                    x.module_id == 2 &&
                    (x.subscription_id == 1 || x.subscription_id == 2)
            )
        ) {

        } else {
            if (
                this.user.user_access.find(
                    (x) =>
                        (x.module_id == 4 || x.module_id == 7) &&
                        (x.subscription_id == 1 || x.subscription_id == 2)
                )
            ) {

            } else {
                this.menuLinks = this.menuLinks.filter((item) => {
                    if (item.label != "AdvizorPro Search" && item.label != "Search") {
                        return item;
                    }
                });
            }
        }

        if (
            this.user.user_access.find(
                (x) =>
                    x.module_id == 4 &&
                    (x.subscription_id == 1 || x.subscription_id == 2)
            )
        ) {

        } else {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label != "InsurancePro Search") {
                    return item;
                }
            });
        }

        if ((this.user.has_access_insurance || this.user.has_access_family_office) && !this.user.has_access_advisor) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label != "Dashboard") {
                    return item;
                }
            });
        }

        if (
            this.user.has_access_advisor || this.user.has_access_hubspot || this.user.has_access_salesforce
        ) {
            this.menuLinks.push({
                label: "CRM Integration",
                routerLink: "/crm/integrations",
                iconType: "pg",
                iconName: "ad_format_circle",
                thumbNailClass: "text-white",
            });
        }

        if (this.user.no_notes_tasks) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label == "My Data") {
                    item.submenu = item.submenu.filter(
                        (val) => val.label != "Tasks"
                    );
                }
                return item;
            });
        }

        if (
            this.user.user_access.find(
                (x) =>
                    x.module_id == 2 &&
                    (x.subscription_id == 4 || x.subscription_id == 3)
            )
        ) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label == "My Data") {
                    item.submenu = item.submenu.filter(
                        (val) => val.label != "My Advisors"
                    );
                }
                return item;
            });
        }

        if (
            this.user.user_access.find(
                (x) => x.module_id == 1 && x.subscription_id == 4
            )
        ) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (item.label == "My Data") {
                    item.submenu = item.submenu.filter(
                        (val) => val.label != "My Plans"
                    );
                }

                if (
                    item.label != "PlanScanner (Beta)" &&
                    item.label != "PlanPro Search"
                ) {
                    return item;
                }
            });
        }

        if (this.user.email_automation) {
            this.menuLinks.push({
                label: "Email Automation",
                routerLink: "/automation",
                iconType: "fa",
                iconName: "envelope",
                thumbNailClass: "text-white"
            });
        }


        let module_count = 0;

        if (this.user.has_access) {
            module_count++;
        }

        if (this.user.has_access_advisor) {
            module_count++;
        }

        if (this.user.has_access_insurance) {
            module_count++;
        }

        if (module_count <= 1) {
            this.menuLinks = this.menuLinks.filter((item) => {
                if (["PlanPro Search", "AdvizorPro Search", "InsurancePro Search"].includes(item.label)) {
                    item.label = 'Search';
                }
                return item;
            });
        }
    }

    getUserInitials() {
        const nameSplit = this.user.name.split(' ');
        return nameSplit.map(word => word[0].toUpperCase()).join('');
    }

    onLoggedout() {
        this.auth.logout();
        this.auth.setExistToken('');
        this.auth.setExistUser('');
        window.location.href = "/";
        // this.router.navigate(['/login']);
    }

    hidePlaceholder() {
        $("#inputSearch").focus();
    }

    setTextEditor() {
        this.modules = {
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                showDenotationChar: false,
                source: (searchTerm, renderList) => {
                    if (searchTerm.length === 0) {
                        renderList(this.mentionFields, searchTerm)
                    } else {
                        const matches = []
                        this.mentionFields.forEach((entry) => {
                            if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                                matches.push(entry);
                            }
                        })
                        renderList(matches, searchTerm);
                    }
                }
            },
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean'],                                         // remove formatting button

                ['link', 'image', 'video']
            ],
            imageHandler: {
                upload: (file) => {
                    return new Promise((resolve, reject) => {

                        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') { // File types supported for image
                            if (file.size < 1000000) { // Customize file size as per requirement

                                // Sample API Call
                                const uploadData = new FormData();
                                uploadData.append('file', file, file.name);

                                return this.emailAutomationService.uploadImage(uploadData).toPromise()
                                    .then((result: any) => {
                                        resolve(result.imageUrl); // RETURN IMAGE URL from response
                                    })
                                    .catch(error => {
                                        reject('Upload failed');
                                        // Handle error control
                                        console.error('Error:', error);
                                    });
                            } else {
                                reject('Size too large');
                                // Handle Image size large logic
                            }
                        } else {
                            reject('Unsupported type');
                            // Handle Unsupported type logic
                        }
                    });
                },
                accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
            } as Options
        };
    }

    @HostListener("window:scroll", ["$event"])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        $("#mainNav a").each(function () {
            var currLink = $(this);

            var refElement = $(currLink.attr("ng-reflect-href"));
            if (
                refElement.position() &&
                refElement.position().top <= windowScroll &&
                refElement.position().top + refElement.height() > windowScroll
            ) {
                $("#mainNav a.active").removeClass("active");
                currLink.addClass("active");
            }
        });
        $("#mainNavProfile a").each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("ng-reflect-href"));
            if (
                refElement.position() &&
                refElement.position().top <= windowScroll &&
                refElement.position().top + refElement.height() > windowScroll
            ) {
                $("#mainNavProfile a.active").removeClass("active");
                currLink.addClass("active");
            }
        });
    }
}
