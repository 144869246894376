import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {

    public _subject = new Subject<object>();
    public event = this._subject.asObservable();

    public _hubSubject = new Subject<object>();
    public hubspotEvent = this._hubSubject.asObservable();

    public _dynamicsSubject = new Subject<object>();
    public dynamicsEvent = this._dynamicsSubject.asObservable();

    constructor(
        private http: HttpClient
    ) { }

    public salesforcePublish(data: object) {
        this._subject.next(data);
    }

    public hubspotPublish(data: object) {
        this._hubSubject.next(data);
    }

    public dynamicsPublish(data: object) {
        this._dynamicsSubject.next(data);
    }

    // getImportOption() {
    //     return this.http.get('/api/integration/import-option');
    // }
    // getImportFrequency() {
    //     return this.http.get('/api/integration/import-frequency');
    // }
    // getUserSavedSearches(type) {
    //     return this.http.get(`/api/integration/type/${type}/user-saved-search`);
    // }
    getIntegrationPreference(type) {
        return this.http.get(`/api/integration/integration_preferences/${type}`);
    }

    getAutoMapping(integrationType: string, mappingType: string) {
        return this.http.post(`/api/integration/integration_auto_mapping/${integrationType}`, {mapping_type: mappingType})
    }

    // getExternalFields(type) {
    //     return this.http.get(`/api/integration/type/${type}/fields`);
    // }
    // getSaveImport(type) {
    //     return this.http.get(`/api/integration/type/${type}/import`);
    // }
    // getPlanProFields() {
    //     return this.http.get('/api/integration/plan-pro-fields');
    // }
    // getImportPreference() {
    //     return this.http.get('/api/integration/import-preference');
    // }
    getFieldMappingPreference(type) {
        return this.http.get(`/api/integration/field_mapping_preferences/${type}`);
    }
   
    submitImport(data, type) {
        return this.http.post(`/api/integration/type/${type}/import`, data);
    }

    submitMapping(data, type) {
        return this.http.post(`/api/integration/type/${type}/mapping`, data);
    }

    getIntegration(type) {
        return this.http.get(`/api/integration/type/${type}`);
    }
    getActivity(data) {
        return this.http.post('/api/integration/crm-activity', data);
    }
    getLogDetail(data) {
        return this.http.post('/api/integration/activity-log-detail', data);
    }
    createIntegration(type, data) {
        return this.http.post(`/api/integration/type/${type}`, data);
    }
    getImportData(type, crd, npn) {
        return this.http.post(`/api/integration/type/${type}/status`, {crd: crd, npn: npn});
    }
    importContact(type, crd, npn) {
        return this.http.post(`/api/integration/type/${type}/import-data`, {crd: crd, npn: npn});
    }
    importContactStatus(id){
        return this.http.get(`/api/integration/import-data/status/${id}`)
    }
    runImport(type, testing) {
        if (testing === true){
            return this.http.post(`/api/integration/${type}/test/`, null);
        }else{
            return this.http.post(`/api/integration/${type}/run/`, null);
        }
    }
    disconnect(type) {
        return this.http.delete(`/api/integration/${type}`);
    }
    getIntegrationStatus(type) {
        return this.http.get(`/api/integration/status/${type}`);
    }


    validateUrl(url: string) {
        return this.http.post('/api/integration/validate', { url });
    }
    validateHubspot(accessToken: string) {
        return this.http.post('/api/integration/validate-hubspot', { accessToken });
    }
    checkConnection(type: string) {
        return this.http.get(`/api/integration/check-connection/${type}`);
    }
    getAccessToken(type: string, data) {
        return this.http.post(`/api/integration/token/${type}`, data);
    }
}
